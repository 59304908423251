import React, { useState, useContext } from "react"
import classnames from "classnames"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Context(s) */
import LocationContext from "~context/currentLocation"

/* Import Global Hook(s) */
import { usePartnersPage } from "~queries/hooks"

/* Import Global Component(s) */
import Image from "~components/image/image"
import LocationNav from '~components/location/location'

import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import BlockContent from "~components/blockContent/blockContent"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"

import PartnersRow from '~components/partners/partnersRow'

/* Import Local Style(s) */
import "./partners.scss"


const Partners = ({ location }) => {

  const [cL] = useContext(LocationContext)
  const currentLocation = cL.activeLocation

	const { pageTitle, introduction, partnersList, partnersListHouston } = usePartnersPage()

	return (
    <Page title={pageTitle} location={location}>
      <PageHeader title={pageTitle} location={location} />
      <PageArticle title={pageTitle} className="partners">

        <PageArticleSection className="partners_intro">
          <div className="font-size--small section_inset font-weight--light-body">
            <BlockContent blocks={introduction?._rawBodyCopy} />
          </div>
        </PageArticleSection>
        
        <LocationNav />

        {currentLocation == 'miamiBeach' && <PageArticleSection className="partners">
        
            {
              partnersList.map((p, i) => {
    
                if(p.title !== undefined){
                  return (<h1 className="font-size--small partners_header section_inset" key={'title_'+ i}>{p.title}</h1>)
                }else if(p.partners !== undefined){
                  return (<PartnersRow partners={p.partners} key={'partners_'+ i} />)
                }else if(p._rawBodyCopy !== undefined){
                  return (<div key={'copy_'+ i} className="font-size--small font-weight--light-body section_inset">
                    <BlockContent blocks={p._rawBodyCopy} />
                  </div>)
                }
    
              })
            }
    
        </PageArticleSection>}

        {currentLocation == 'houston' && <PageArticleSection className="partners">
        
            {
              partnersListHouston.map((p, i) => {
    
                if(p.title !== undefined){
                  return (<h1 className="font-size--small partners_header section_inset" key={'title_'+ i}>{p.title}</h1>)
                }else if(p.partners !== undefined){
                  return (<PartnersRow partners={p.partners} key={'partners_'+ i} />)
                }else if(p._rawBodyCopy !== undefined){
                  return (<div key={'copy_'+ i} className="font-size--small section_inset font-weight--light-body">
                    <BlockContent blocks={p._rawBodyCopy} />
                  </div>)
                }
    
              })
            }
    
        </PageArticleSection>}
      </PageArticle>
      <Footer />
    </Page>
  )

}

export default Partners